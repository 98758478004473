var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"ucsc-visualHero",class:[
    [
      'ucsc-visualHero--hasImg',
      `ucsc-visualHero--hasImg-x-${_vm.imgXPosition}`,
      `ucsc-visualHero--hasImg-y-${_vm.imgYPosition}`,
    ],
  ],style:(_vm.style)},[(_vm.$slots.caption)?_c('div',{staticClass:"ucsc-visualHero__caption"},[_vm._t("caption")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"ucsc-visualHero__inner",class:[
        `ucsc-visualHero__inner--${_vm.innerJustify}`,
        `ucsc-visualHero__inner--${_vm.size}`,
      ]},[_vm._t("default"),_vm._v(" "),(_vm.$slots.description)?_c('div',{ref:"Description"},[_vm._t("description")],2):_vm._e(),_vm._v(" "),(_vm.$slots.description && _vm.descriptionIcon)?_c('div',{class:[
          'flex justify-center mt-8',
          {
            'absolute bottom-0 left-0 right-0 py-8': !_vm.descriptionExpanded,
          },
        ]},[_c('SvgIcon',{class:[
            'ucsc-arrow ucsc-arrow--small',
            {
              'rotate-180': _vm.descriptionExpanded,
            },
          ],attrs:{"name":"angle-down"},on:{"click":_vm.onArrowDownClick}})],1):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }