<template>
  <div>
    <ValidationObserver
      v-slot="{ errors }"
      ref="FormSearchValidationObserver"
      :class="[
        'grid gap-x-16 gap-y-8',
        {
          'md:grid-cols-2': cols === 2,
        },
      ]"
      tag="form"
      :data-form="formData"
      @submit.prevent="onFormSubmit"
    >
      <AutocompleteWithValidation
        name="geo"
        :geo="geo"
        :geo-zones="geoZones"
        rules="required"
        :errors="errors"
        :class="errors?.['geo']?.[0] && 'mb-10'"
        :label="showLabels ? 'Comune, Provincia, Zona*' : ''"
        placeholder="Comune, Provincia, Zona*"
        item-label-field="testo"
        @select="onGeoSelect"
        @select-zone="onSelectZone"
        @focus="onTealiumFormFirstFieldFocus"
      />
      <DropdownWithValidation
        :value="section"
        :options="sections"
        :show-options-length="false"
        rules="required"
        :errors="errors"
        :label="showLabels ? 'Categoria*' : ''"
        placeholder="Categoria*"
        :show-placeholder-option="false"
        field="label"
        name="section"
        @select="onSectionSelect"
      />
      <DropdownWithValidation
        name="typology"
        :value="typology"
        :options="computedTypologies"
        :show-options-length="false"
        :label="showLabels ? 'Tipologia' : ''"
        placeholder="Tipologia"
        :show-placeholder-option="false"
        @select="onTypologySelect"
      />
      <DropdownRangeWithValidation
        v-model="surface"
        name="surface"
        :label="showLabels ? 'Metri quadri' : ''"
        placeholder="Metri quadri"
        symbol="m²"
        @input="onFormFieldEvent($event, 'surface')"
      />
      <DropdownRangeWithValidation
        v-model="price"
        name="price"
        :label="showLabels ? 'Prezzo' : ''"
        placeholder="Prezzo"
        symbol="€"
        @input="onFormFieldEvent($event, 'price')"
      />
      <DropdownRangeWithValidation
        v-if="fields.includes('room')"
        v-model="rooms"
        name="rooms"
        :label="showLabels ? 'Locali' : ''"
        placeholder="Locali"
        @input="onFormFieldEvent($event, 'rooms')"
      />
      <div
        :class="[
          {
            'md:text-right md:col-span-2': cols === 2,
          },
        ]"
      >
        <OButton
          :class="[
            'w-full',
            {
              'md:w-auto': cols === 2,
            },
          ]"
          variant="primary"
          native-type="submit"
          :name="buttonName"
          type="submit"
        >
          <SvgIcon
            name="search-new-white"
            class="ucsc-icon--button ucsc-icon--button__left"
          />
          Cerca
        </OButton>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
// Libs
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

// Mixins
import FormMixin from '~/mixins/form'

// Static assets
import {
  ListingQueryMapToUrl,
  ListingRoutes,
  computedTypologies,
} from '~/assets/js/listing'

import AutocompleteWithValidation from '~/components/AutocompleteWithValidation.vue'
import DropdownWithValidation from '~/components/DropdownWithValidation.vue'
import DropdownRangeWithValidation from '~/components/DropdownRangeWithValidation.vue'

export default {
  components: {
    AutocompleteWithValidation,
    DropdownWithValidation,
    DropdownRangeWithValidation,
  },
  mixins: [FormMixin],

  props: {
    cols: {
      type: Number,
      default: 1,
    },

    showLabels: {
      type: Boolean,
      value: false,
    },

    fields: {
      type: Array,
      default() {
        return []
      },
    },

    formData: {
      type: String,
      default: '',
    },

    buttonName: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      surface: {},
      price: {},
      rooms: {},
    }
  },

  async fetch() {
    const { $route, $utilities, $store } = this
    const { search: searchState } = $store.state
    const { params } = $route

    if (!this.sections.length) {
      this.setSections($utilities.sections)
    }

    const section = params.section
      ? searchState.sections.find((it) => it.slug === params.section)
      : searchState.sections[0]
    this.setSection(section)

    if (!this.typologies.length) {
      await this.fetchTypologies()
    }

    if ($route.name !== 'property') return

    const { province: provinceSlug, city: citySlug } = params

    if (!searchState.geo) {
      const { geo } = await $utilities.findGeo(provinceSlug, citySlug)
      this.setGeo(geo)
    }
  },

  computed: {
    computedTypologies,

    ...mapState('search', [
      'geo',
      'geoZones',
      'section',
      'sections',
      'typology',
    ]),

    ...mapGetters({
      typologies: 'search/getTypologies',
    }),
  },

  methods: {
    ...mapActions('search', ['fetchTypologies']),
    ...mapMutations('search', [
      'setTypology',
      'setSections',
      'setSection',
      'setGeo',
      'addGeoZone',
      'removeGeoZone',
    ]),

    onGeoSelect(payload) {
      this.tealiumFormUpdateLastField('geo')
      this.setGeo(payload)
    },

    onSelectZone(payload) {
      if (!payload.selected) {
        this.removeGeoZone(payload)
        return
      }

      this.addGeoZone(payload)
    },

    onSectionSelect(payload) {
      this.setSection(payload)

      // We have a new section, so the typologies must be refreshed.
      this.fetchTypologies()
      this.setTypology(null)

      this.tealiumFormUpdateLastField('category')
    },

    onTypologySelect(payload) {
      this.setTypology(payload)

      this.tealiumFormUpdateLastField('typology')
    },

    onFormSubmit() {
      const { FormSearchValidationObserver } = this.$refs

      FormSearchValidationObserver.validate()

      if (FormSearchValidationObserver.flags.invalid) {
        return
      }

      const section = this.section.slug
      const province = this.geo.provinciaSigla.toLowerCase()
      const city = this.geo.comuneKeyurl
      const typology = this.typology?.slug
      const zones = this.geoZones

      const params = {
        section,
        province,
        ...(city ? { city } : {}),
        ...(typology ? { typology } : {}),
        ...(zones.length === 1 ? { zone: zones[0].slug } : {}),
      }

      const roomsFrom = this.rooms?.from
      const roomsTo = this.rooms?.to
      const surfaceFrom = this.surface?.from
      const surfaceTo = this.surface?.to
      const priceFrom = this.price?.from
      const priceTo = this.price?.to

      const query = {
        ...(zones.length > 1
          ? { zone: zones.map((it) => it.id).join(',') }
          : {}),
        ...(roomsFrom ? { [ListingQueryMapToUrl.roomsFrom]: roomsFrom } : {}),
        ...(roomsTo ? { [ListingQueryMapToUrl.roomsTo]: roomsTo } : {}),
        ...(surfaceFrom
          ? { [ListingQueryMapToUrl.surfaceFrom]: surfaceFrom }
          : {}),
        ...(surfaceTo ? { [ListingQueryMapToUrl.surfaceTo]: surfaceTo } : {}),
        ...(priceFrom ? { [ListingQueryMapToUrl.priceFrom]: priceFrom } : {}),
        ...(priceTo ? { [ListingQueryMapToUrl.priceTo]: priceTo } : {}),
      }

      let name

      switch (true) {
        case Boolean(!city):
          name = ListingRoutes.NoCity
          break

        case Boolean(!zones.length):
          name = ListingRoutes.NoZone
          break

        default:
          name = ListingRoutes.Listing
          break
      }

      const route = { name, params, query }

      this.$emit('search')
      this.$router.push(route)
    },
  },
}
</script>
