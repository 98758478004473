<template>
  <!--
    TODO: use computed to overwrite only
    :style="style"
    :style="'background-image: default...' + computedStyles" // computedStyles = background-image: overwrite...
  -->
  <section
    class="ucsc-visualHero"
    :class="[
      [
        'ucsc-visualHero--hasImg',
        `ucsc-visualHero--hasImg-x-${imgXPosition}`,
        `ucsc-visualHero--hasImg-y-${imgYPosition}`,
      ],
    ]"
    :style="style"
  >
    <div v-if="$slots.caption" class="ucsc-visualHero__caption">
      <slot name="caption"></slot>
    </div>

    <div class="container">
      <div
        class="ucsc-visualHero__inner"
        :class="[
          `ucsc-visualHero__inner--${innerJustify}`,
          `ucsc-visualHero__inner--${size}`,
        ]"
      >
        <slot></slot>
        <div v-if="$slots.description" ref="Description">
          <slot name="description"></slot>
        </div>
        <div
          v-if="$slots.description && descriptionIcon"
          :class="[
            'flex justify-center mt-8',
            {
              'absolute bottom-0 left-0 right-0 py-8': !descriptionExpanded,
            },
          ]"
        >
          <SvgIcon
            name="angle-down"
            :class="[
              'ucsc-arrow ucsc-arrow--small',
              {
                'rotate-180': descriptionExpanded,
              },
            ]"
            @click="onArrowDownClick"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// Libs
import debounce from 'lodash/debounce'

const GradientDefault =
  'linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.25) 100%)'

export default {
  props: {
    img: {
      type: String,
      default: null,
    },

    mobileImg: {
      type: String,
      default: null,
    },

    imgXPosition: {
      type: String,
      default: 'center',
    },

    imgYPosition: {
      type: String,
      default: 'center',
    },

    size: {
      type: String,
      default: 'medium',
    },

    innerJustify: {
      type: String,
      default: 'between',
    },

    gradient: {
      type: [String, Boolean],
      default: GradientDefault,
    },

    preload: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      computedImg: null,
      descriptionExpanded: false,
      descriptionIcon: false,
    }
  },
  head() {
    if (!this.preload) {
      return
    }

    function generateLinkObj(href) {
      return {
        rel: 'preload',
        as: 'image',
        href,
        fetchpriority: 'high',
      }
    }

    const link = []

    link.push(
      generateLinkObj(
        this.$img('/assets/img/unicreditsubitocasa-placeholder.png')
      )
    )

    if (this.img) {
      link.push(generateLinkObj(this.img))
    }

    if (this.$device.isMobile && this.mobileImg) {
      link.push(generateLinkObj(this.mobileImg))
    }

    return { link }
  },

  computed: {
    style() {
      if (!this.computedImg) {
        const placeholderImage = this.$img(
          '/assets/img/unicreditsubitocasa-placeholder.png'
        )

        return `background-image: ${GradientDefault}, url(${placeholderImage}); background-position-y: center;`
      }

      return this.gradient
        ? `background-image: ${this.gradient}, url(${this.computedImg})`
        : `background-image: url(${this.computedImg})`
    },
  },
  mounted() {
    this.setComputedImg()

    this.onWindowResize = debounce(this.onWindowResize, 500)

    window.addEventListener('resize', this.onWindowResize)
  },

  updated() {
    this.onWindowResize()
  },

  destroyed() {
    window.removeEventListener('resize', this.onWindowResize)
  },

  methods: {
    onWindowResize() {
      const descriptionRef = this.$refs.Description
      if (descriptionRef) {
        const descriptionRefChild = descriptionRef.firstChild
        const descriptionIcon =
          descriptionRefChild.offsetHeight < descriptionRefChild.scrollHeight

        if (!this.descriptionExpanded) {
          this.descriptionIcon = descriptionIcon
        }
      }

      this.setComputedImg()
    },

    setComputedImg() {
      const isMobileWidth = window.innerWidth <= 768
      this.computedImg =
        this.mobileImg && isMobileWidth ? this.mobileImg : this.img
    },

    onArrowDownClick() {
      const descriptionExpanded = !this.descriptionExpanded

      this.descriptionExpanded = descriptionExpanded
      this.$emit('description-collapse', descriptionExpanded)
    },
  },
}
</script>

<style lang="postcss" scoped>
.ucsc-visualHero {
  @apply relative;

  &--hasImg {
    @apply bg-cover bg-no-repeat;
  }

  &--hasImg-x-1-mobile {
    background-position-x: 10%;

    @screen md {
      background-position-x: center;
    }
  }

  &--hasImg-x-2-mobile {
    background-position-x: 20%;

    @screen md {
      background-position-x: center;
    }
  }

  &--hasImg-x-3-mobile {
    background-position-x: 30%;

    @screen md {
      background-position-x: center;
    }
  }

  &--hasImg-x-5-listing {
    background-position-x: 50%;

    @screen md {
      background-position-x: left;
    }
  }

  &--hasImg-x-5-mobile {
    background-position-x: 50%;

    @screen md {
      background-position-x: center;
    }
  }

  &--hasImg-x-100-mobile {
    background-position-x: 100%;

    @screen md {
      background-position-x: center;
    }
  }

  &--hasImg-x-center {
    background-position-x: center;

    @screen md {
      background-position-x: center;
    }
  }

  &--hasImg-x-left {
    background-position-x: left;

    @screen md {
      background-position-x: left;
    }
  }

  &--hasImg-x-right {
    background-position-x: right;

    @screen md {
      background-position-x: right;
    }
  }

  &--hasImg-y-center {
    background-position-y: center;

    @screen md {
      background-position-y: center;
    }
  }

  &--hasImg-y-top {
    background-position-y: top;

    @screen md {
      background-position-y: top;
    }
  }

  &--hasImg-y-bottom {
    background-position-y: bottom;

    @screen md {
      background-position-y: bottom;
    }
  }

  &__imgFlipped {
    @apply absolute inset-0 -z-10 bg-cover bg-no-repeat transform -scale-x-1;

    @screen md {
      @apply scale-100;
    }

    &--x-100-mobile {
      background-position-x: 100%;

      @screen md {
        background-position-x: center;
      }
    }

    &--y-center {
      background-position: left;

      @screen md {
        background-position: center;
      }
    }

    &--y-top {
      background-position: left top;

      @screen md {
        background-position: center top;
      }
    }

    &--y-bottom {
      background-position: left bottom;

      @screen md {
        background-position: center bottom;
      }
    }
  }

  &__inner {
    @apply flex flex-col pt-8 pb-8 text-white;

    @screen md {
      @apply pt-16 pb-16;
    }

    &--small {
      @apply h-120;
    }

    &--medium {
      @apply h-120;

      @screen md {
        @apply h-180;
      }
    }

    &--large {
      @apply h-auto;
    }

    &--between {
      @apply justify-between;
    }

    &--center {
      @apply justify-center;
    }

    &--start {
      @apply justify-start;
    }

    &--end {
      @apply justify-end;
    }
  }

  &__caption {
    @apply flex justify-center items-center absolute bottom-0 left-0 w-full text-center text-white h-48;

    background: rgba(0, 0, 0, 0.5);
  }
}
</style>
